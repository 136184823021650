@import "~@/assets/sass/_function";

.Register {
  padding: 70px 40px 60px;
  height: 100%;

  .panel-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  .panel-form {
    flex: 1;
    gap: 24px;
    // margin-bottom: 68px;
    .ant-form-item {
      margin: 0;
    }
    .ant-form-item-label {
      display: none;
    }
    &-input {
      height: 54px;
      padding: 12px 20px;
      border-radius: 40px;
      background: #ffffff;
      letter-spacing: 0.04em;
      box-sizing: border-box;
      border: 1px solid #b5bae1;
      &::placeholder {
        color: $text-color-4;
      }
    }
  }
  .register-form-bottom {
    margin-top: auto;
    .register-button {
      height: auto;
      margin-bottom: 24px;
      padding: 19px;
      border-radius: 44px;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.04em;
      .ic {
        height: 30px;
        font-size: 30px;
      }
      &:hover,
      &:active {
        opacity: 0.9;
        outline: none;
        background: linear-gradient(270deg, #2450ff 0%, #6d24ff 100%) !important;
      }
    }
  }
}
