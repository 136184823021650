@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.3s;
}

@keyframes downFadeIn {
  from {
    opacity: 0;
    transform: translateZ(-40%);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.downFadeIn {
  animation: downFadeIn 0.3s;
}
