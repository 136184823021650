@import "~@/assets/sass/_function";

.Finish {
  padding: 40px 40px 88px;
  background: url("https://oss.5rs.me/oss/uploadfe/png/5aba1536cec569677d520ca54c9ebd9a.png")
    no-repeat center center/100% 100%;
  pointer-events: none;

  .finish-content {
    max-width: 50%;
  }
  .finish {
    color: $text-color;

    &-icon {
      width: 144px;
      margin-bottom: 15px;
    }
    &-title {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 500;
    }
    &-subtitle {
      font-size: 14px;
      margin-bottom: 35px;
    }
    &-code {
      padding: 10px;
      width: 200px;
      height: 200px;
      border-radius: 4px;
      background-color: $white;
    }
  }
}
