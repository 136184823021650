@import "~@/assets/sass/_function";

.VoyagePage {
  position: relative;
  width: 100%;
  background-color: $white;

  .form-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 114px;
    height: 38px;
    font-size: 16px;
    color: $white;
    background: linear-gradient(270deg, #5e2ffb 0%, #876ce1 100%);
    background-blend-mode: normal, normal;
    border-radius: 18.4px;
    &:hover {
      background-image: linear-gradient(92deg, #9f70de 0%, #822bfa 100%),
        linear-gradient(-90deg, #8128fa 0%, #9f74de 100%);
    }
  }

  .gray-65 {
    color: #000000a6;
  }

  .gray-42 {
    color: #424242;
  }

  .part-color {
    color: #8e55dc;
  }

  .part-color-dark {
    color: #3b10c4;
  }

  .part-cicle {
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-image: linear-gradient(92deg, #9f70de 0%, #822bfa 100%),
      linear-gradient(-90deg, #8331f8 0%, #9f73de 100%);
    background-blend-mode: normal, normal;
    opacity: 0.5;
  }

  .part-title {
    display: inline-flex;
    margin-bottom: 6px;
    font-size: 45px;
    line-height: 50px;
    font-weight: 600;
    font-family: "Alimama", sans-serif;
  }
  .part-title-img {
    width: calc(100% + 17px);
    margin-left: -17px;
    margin-bottom: 26px;
    &::before {
      right: 0;
      top: 0;
      transform: translate(35%, -25%);
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
  .part-subtitle {
    font-size: 18px;
    margin-bottom: 63px;
    color: #1a1a1a;
    letter-spacing: 1px;
    font-family: "DreamHanSans", sans-serif;
  }

  .voyage-part {
    margin: 0 auto;
    max-width: 1015px;
  }

  .voyage-content {
    position: relative;
    z-index: 100;
    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      z-index: -1;
    }
    &::before {
      top: 0;
      height: 1256px;
      background: url("https://oss.5rs.me/oss/uploadfe/png/bffe6c2a060b02392d19050d7c50540a.png")
        no-repeat left top / 100% 100%;
    }

    &::after {
      bottom: 0;
      height: 2579px;
      background: url("https://oss.5rs.me/oss/uploadfe/png/3956ea0156af94f3ab81a9454d175659.png")
        no-repeat left 430px / 100% 100%;
    }
  }
  .voyage-header {
    padding: 66px 80px 97px;
    justify-content: space-between;
    .voyage-logo {
      width: 128px;
      height: 45px;
    }
    &-tabs {
      gap: 13px;
    }
    .login-form-btn {
      .form-trigger {
        color: #292929;
        border: 1px solid #822bfa;
        background: transparent;
        &:hover {
          color: #822bfa;
        }
      }
    }
  }
}
