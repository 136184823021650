@import "~@/assets/sass/_function";

.voyage-part {
  &-four {
    padding: 370px 0 104px;
    .voyage-part-logo {
      width: 80px;
      height: 80px;
      margin-bottom: 24px;
    }
    .LoginForm {
      .form-trigger {
        width: 171px;
        height: 51px;
        border-radius: 25px;
        font-size: 24px;
        transition: all ease-in-out 0.5s;
        &:hover {
          transform: scale(1.1);
        }
        // animation: pulsate-bck 0.5s ease-in-out infinite both;
      }
    }
  }

  &.slide-in-bottom {
    animation: slide-in-bottom 0.6s ease-in-out both;
    @keyframes slide-in-bottom {
      0% {
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 100%;
        filter: blur(40px);
        opacity: 0;
      }
      100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
      }
    }

    @keyframes pulsate-bck {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
