@import "~@/assets/sass/_function";

.voyage-part {
  &-two {
    margin-top: 230px !important;
    &-title {
      .part-title-wrap {
        width: fit-content;
        text-align: center;
        .part-cicle {
          right: 0;
          top: 0;
          transform: translate(50%, -20%) scale(0.8);
        }
        .part-title,
        .part-subtitle {
          opacity: 0;
          text-align: center;
        }
      }
    }
    .part-two-list {
      gap: 21px;
      .part-two-item {
        gap: 48px;
        align-self: baseline;
        height: 133px;
        width: 483px;
        align-items: center;
        padding: 0 50px;
        border-radius: 61.89px;
        opacity: 0;
        color: #191919;
        background-color: $white;
        box-shadow:
          0.8px 0.5px 16.8px 0.2px rgba(91, 90, 90, 0.36),
          inset 3px 0px 0px 0px #392cfd;
        line-height: 1;

        font-weight: 600;
        font-family: "DreamHanSans", sans-serif;
        &:nth-of-type(2) {
          box-shadow:
            0.8px 0.5px 16.8px 0.2px rgba(91, 90, 90, 0.36),
            inset -3px 0px 0px 0px #392cfd;
        }
        &-icon {
          width: 50px;
          height: 51px;
        }
        .item-title {
          font-size: 29px;
          color: #1e303a;
          white-space: nowrap;
        }
      }
    }
    &-left {
      position: relative;
      padding: 92px 0;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 663px;
        height: 617px;
        background: url("https://oss.5rs.me/oss/uploadfe/png/2a28a581f765592d136358001c70855f.png") no-repeat left top /
          100% 100%;
        transform: translate(-15%, 0);
        z-index: -1;
      }
    }
    &-right {
      position: relative;
      right: 0;
      align-self: baseline;
      transform: translate(225px, 125px);
      .part-cicle {
        left: 0;
        top: 0;
        opacity: 0;
        transform: translate(-30%, -35%) scale(0.8);
      }
      .right-subtitle {
        margin-bottom: 27px;
        font-size: 19px;
        font-family: "DreamHanSans", sans-serif;
      }
      .part-title,
      .right-subtitle {
        opacity: 0;
      }
    }
    &-bottom {
      margin-top: 115px;
      gap: 43px;
      .part-two-bottom-item {
        align-self: baseline;
      }
      .part-icon {
        width: 54px;
        margin-bottom: 20px;
      }
      .part-title {
        &.item-title {
          margin-bottom: 14px;
          font-size: 32px;
          line-height: 1;
        }
      }
      .item-subtitle {
        max-width: 126px;
        font-size: 9px;
        text-align: center;
        letter-spacing: 1px;
      }
      .element-item {
        opacity: 0;
      }
    }
  }
}
