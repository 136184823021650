@import "~@/assets/sass/_function";

.voyage-part {
  &-three {
    margin-top: 448px !important;
    &-title {
      position: relative;
      .part-cicle {
        left: 0;
        top: 0;
        transform: translate(-40%, -33%) scale(0.8);
      }
      .part-title,
      .part-subtitle {
        opacity: 0;
      }
      .part-title {
        margin-bottom: 10px !important;
      }
      .part-subtitle-desc {
        width: 541px;
        font-size: 19px;
        text-align: justify;
      }
    }
    .part-three-content {
      gap: 44px;
      margin-top: 337px;
    }
    &-left {
      position: relative;
      width: 582px;
      transform: translate(0, -166px);
      // background: url("https://oss.5rs.me/oss/uploadfe/png/d0894864cc3cecb1b834675bf39c6abb.png")
      //   no-repeat left top/100% 100%;
      .three-left-image-wrap {
        height: 520px;
      }
      .slide-report {
        position: absolute;
        bottom: -20px;
        padding-bottom: 8px;
        display: inline-flex;
        flex-direction: column;
        background-image: linear-gradient(
          187deg,
          rgba(#c993ff, 1) 0%,
          rgba(#f1eefc, 0) 100%
        );
        border-radius: 11px;
        opacity: 0;
        .lable-title {
          padding: 6px 22px;
          margin-bottom: 8px;
          display: inline-flex;
          justify-content: center;
          font-size: 22px;
          line-height: 1;
          color: $white;
          border-radius: 8px;
          background-image: linear-gradient(92deg, #8a70df 0%, #5c2bf9 100%),
            linear-gradient(-90deg, #5c2bf9 0%, #8a70df 100%);
        }
        .lable-subtitle {
          display: flex;
          gap: 2px;
          margin-bottom: 4px;
          padding: 0 10px;
          font-size: 13px;
          color: #191919;
          &::before {
            content: "●";
            transform: scale(0.8);
          }
        }
        .slide-report-lable {
          transform: translate(-270px, 0);
        }
      }
      .slick-slider {
        .slick-track {
          height: 556px;
        }
        .slick-thumb {
          gap: 14px;
          width: 430px;
          padding: 15px 28px;
          background-color: #ffffff;
          border-radius: 22px;
          bottom: -120px;
          cursor: pointer;
          opacity: 0;
          box-shadow: 1px 1px 17px 0 rgba(#000, 0.36);
          > li {
            width: 42px;
            height: 42px;
            text-indent: 0;
            opacity: 0.36;
            &.slick-active {
              opacity: 1;
            }
          }
        }
      }
    }
    &-right {
      &-chart {
        opacity: 0;
      }
      &-info {
        position: relative;
        .part-cicle {
          left: 0;
          top: 0;
          transform: translate(-30%, -20%) scale(0.8);
        }
        .part-three-title {
          font-size: 35px;
          white-space: nowrap;
          font-family: "Alimama", sans-serif;
        }
        .part-three-subtitle {
          margin-bottom: 30px;
          font-size: 28px;
          line-height: 1.2;
          white-space: nowrap;
        }
        .part-three-subtitle + .part-subtitle {
          max-width: 435px;
          margin-bottom: 51px;
          font-size: 19px;
          letter-spacing: 0px;
          text-align: justify;
        }
      }
    }
  }
}
