@import "~@/assets/sass/_function";

.voyage-part {
  &-one {
    .voyage-part-content {
      position: relative;
    }
    &-left {
      width: 368px;
      padding-top: 79px;
      flex-shrink: 0;
      .LoginForm {
        display: inline-flex;
        .form-trigger {
          width: 171px;
          height: 51px;
          font-size: 24px;
          border-radius: 24.75px;
        }
      }
      .part-title {
        &.part-slogan {
          font-size: 55px;
          line-height: 1;
          font-weight: normal;
          letter-spacing: -2px;
          margin-bottom: 13px;
          white-space: nowrap;
          font-family: auto;
        }
        &-img {
          position: relative;
          .part-cicle {
            right: 0;
            top: 0;
            // opacity: 0;
            transform: translate(50%, -25%) scale(0.8);
          }
        }
      }
      .part-subtitle {
        white-space: nowrap;
      }
    }
    &-right {
      position: relative;
      width: 100%;
      // width: 50rem;
      .part-one {
        &-play {
          position: absolute;
          bottom: 13px;
          width: 140px;
          height: 148px;
          opacity: 0.89;
          z-index: 10;
          animation: bounce-in-top 0.75s both;
          animation-delay: 1s;
          @keyframes bounce-in-top {
            0% {
              transform: translateY(-300px);
              animation-timing-function: ease-in;
              opacity: 0;
            }
            38% {
              transform: translateY(0);
              animation-timing-function: ease-out;
              opacity: 1;
            }
            55% {
              transform: translateY(-65px);
              animation-timing-function: ease-in;
            }
            72% {
              transform: translateY(0);
              animation-timing-function: ease-out;
            }
            81% {
              transform: translateY(-28px);
              animation-timing-function: ease-in;
            }
            90% {
              transform: translateY(0);
              animation-timing-function: ease-out;
            }
            95% {
              transform: translateY(-8px);
              animation-timing-function: ease-in;
            }
            100% {
              transform: translateY(0);
              animation-timing-function: ease-out;
            }
          }
        }
        &-video {
          position: absolute;
          top: 0;
          width: 710px;
          left: 110px;
          .video-image {
            position: relative;
            height: auto;
            align-self: baseline;
            &:nth-child(1) {
              z-index: 6;
            }
            &:nth-child(2) {
              left: -70px;
              top: 161px;
              z-index: 5;
            }
            &:nth-child(3) {
              left: -103px;
              top: 13px;
              z-index: 4;
            }
            &:nth-child(4) {
              left: -143px;
              top: 77px;
              z-index: 3;
            }
            &:nth-child(5) {
              left: -187px;
              top: 157px;
              z-index: 2;
            }
            &:nth-child(6) {
              left: -282px;
              top: -47px;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.videoModal {
  .ant-modal-close {
    color: #fff;
    &:hover {
      color: rgba(#fff, 0.88);
    }
  }
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    background-color: transparent;
  }
  video {
    vertical-align: bottom;
    border-radius: 8px;
  }
}
