@import "~@/assets/sass/_function";

.Password {
  height: 100%;
  padding: 15px 20px;
  .panel-head {
    margin-bottom: 22px;
  }
  .panel-form {
    padding: 0 20px;
    flex: 1;
    gap: 18px;
    .ant-form-item {
      margin-bottom: 0;
    }
    &-wrap {
      gap: 12px;
      .ant-form-item-row {
        display: flex;
        flex-direction: column;
        .ant-form-item {
          &-label {
            margin-bottom: 10px;
            text-align: left;
            > label {
              height: auto;
              font-size: 16px;
              line-height: 1;
              &::before {
                display: none;
              }
            }
          }
          &-control {
            flex: auto;
          }
          // &-explain-error {
          //   display: none;
          // }
        }
      }
    }
    &-input {
      padding: 8px 20px;
      border-radius: 40px;
      border: 1px solid #b5bae1;
      > .ant-input:focus {
        box-shadow: inset 0 0 0 50px $white !important;
      }
      .anticon {
        color: $text-color-6;
      }
      &::placeholder {
        color: $text-color-4;
      }
    }
    &-password_button {
      height: auto;
      margin-top: 36px;
      padding: 7px;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.04em;
      &:hover,
      &:active {
        opacity: 0.9;
        outline: none;
        background: linear-gradient(270deg, #2450ff 0%, #6d24ff 100%) !important;
      }
    }
  }
}
