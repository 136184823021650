@import "~@/assets/sass/_common.scss";
@import "~@/assets/sass/_animation.scss";
@import "~@/assets/sass/_base";

@import "~@/assets/sass/_antd.scss";

body {
  // 定义全局css 变量
  --minWidth: 1440px;
  --maxWidth: 1440px;
  --borderBase: 4px;

  --fw500: 600;
  &[ismac] {
    --fw500: 500;
  }
}

#App {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: var(--minWidth);
}

.no-scroll {
  overflow: hidden;
}

@font-face {
  font-family: "Alimama";
  src: url("https://cdn.5rs.me/cdn/fonts/Alimama.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DreamHanSans";
  src: url("https://cdn.5rs.me/cdn/fonts/DreamHanSansCN-W3.ttf") format("truetype"); /* TTC */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
