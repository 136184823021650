@import "~@/assets/sass/_function";

$loginGray: #eef2fb;
$inputGray: #f1f3f8;

.LoginForm {
  .form-panel {
    &-banner {
      flex: 6;
      max-width: 580px;
      .slick-track {
        img {
          max-height: 590px;
          border-radius: 8px 0 0 8px;
          object-fit: cover;
        }
      }
    }
    &-content {
      flex: 4;
      border-radius: 8px;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.84) 100%);
      .panel-head {
        color: $text-color-6;
        &-title {
          margin: auto;
          font-size: 24px;
          font-weight: 600;
          color: $text-color;
        }
      }
    }
  }
  .LoginFormModal {
    min-width: 1020px;
    .ant-modal-content {
      min-height: 590px;
      padding: 0;
      border-radius: 8px;
    }
  }
}
