@import "~@/assets/sass/_function";

.Login {
  height: 100%;
  padding: 16px 12px 32px;
  .panel-head {
    margin-bottom: 44px;
  }
  .panel-agent {
    margin: 44px auto;
    font-size: 18px;
  }
  .panel-form {
    margin-top: auto;
    padding: 0 28px;
    &-wrap {
      gap: 12px;
      margin-bottom: 24px;
    }
    &-input {
      padding: 16px 20px;
      border-radius: 40px;
      border: 1px solid #b5bae1;
      > .ant-input:focus {
        box-shadow: inset 0 0 0 50px $white !important;
      }
      .anticon {
        color: $text-color-6;
      }
      &::placeholder {
        color: $text-color-4;
      }
    }
    &-login_button {
      height: auto;
      padding: 7px 50px;
      margin: 100px 0 14px;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.04em;
      &:hover,
      &:active {
        opacity: 0.9;
        outline: none;
        background: linear-gradient(270deg, #2450ff 0%, #6d24ff 100%) !important;
      }
    }
  }
}
