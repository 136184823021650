@charset "UTF-8";
@import "variables";

/** text color **/
.text-default {
  color: $text-color-light;
}

.text-primary {
  color: $primary-color;
}

.text-gray {
  color: $text-color-gray;
}

.text-hover {
  color: $text-color;
  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.text-primary-hover {
  color: $primary-color;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.text-secondary {
  color: $secondary-color;
}

.text-contrast {
  color: $contrast-color;
}

.text-info {
  color: $info-color;
}

.text-warning {
  color: $warning-color;
}
.font_gy_1 {
  color: $font_gy_1;
}
.font_gy_2 {
  color: $font_gy_2;
}
.font_gy_3 {
  color: $font_gy_3;
}
.font_gy_4 {
  color: $font_gy_4;
}
.text-gray3 {
  color: #333;
}

.text-gray6 {
  color: #666;
}

.text-gray9 {
  color: #999;
}

.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

.text-weight {
  font-weight: 500;
}

.text-bold {
  font-weight: 600;
}

/** font size **/
.f12 {
  font-size: 12px;
  line-height: 20px;
}

.f14 {
  font-size: 14px;
  line-height: 22px;
}

.f16 {
  font-size: 16px;
  line-height: 24px;
}

.f18 {
  font-size: 18px;
  line-height: 26px;
}

.f20 {
  font-size: 20px;
  line-height: 28px;
}

.f24 {
  font-size: 24px;
  line-height: 32px;
}

.f30 {
  font-size: 30px;
  line-height: 38px;
}

.f46 {
  font-size: 46px;
  line-height: 54px;
}

.f68 {
  font-size: 68px;
  line-height: 76px;
}

/** width **/
.w120 {
  width: 120px;
}

.w160 {
  width: 160px;
}

// margin
.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt60 {
  margin-top: 60px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}

.ml120 {
  margin-left: 120px !important;
}

.gap10 {
  gap: 10px;
}

.gap15 {
  gap: 15px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

// display
.inline-block {
  display: inline-block !important;
  vertical-align: middle !important;
}

.text-underline {
  text-decoration: underline;
}

// width、height
.h100 {
  height: 100%;
}

.w100 {
  width: 100px;
}

.block {
  display: block;
}

//flex
.dis-flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.row-flex {
  flex-direction: row;
}

.col-flex {
  flex-direction: column;
}

.flex-justify {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.align-base {
  align-self: baseline;
}

.flex1 {
  flex: 1;
  flex-shrink: 0;
}

.nowrap {
  white-space: nowrap;
}

/** 通用页面样式 **/
.bg-white {
  padding: $paddingBase;
  border-radius: $radiusBase;
  background-color: $white;
}

.primary-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.cursor {
  cursor: pointer;
}

.required {
  position: relative;
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
}

.hidden {
  display: none !important;
}

.table-ope-btn {
  display: flex;
  color: $primary-color;
  cursor: pointer;
  gap: 16px;
}

.page-title {
  display: inline-flex;
  position: relative;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: rgba($primary-color, 0.2);
  }
}

.linear-btn {
  @extend %linear-btn;
}

.title {
  font-size: 16px;
  font-weight: $fw500;
  line-height: 24px;
}

//.scrollbar {
//  &::-webkit-scrollbar {
//    width: 5px;
//    height: 5px;
//  }
//  &::-webkit-scrollbar-thumb {
//    /*滚动条里面小方块*/
//    border-radius: 10px;
//    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
//    background: rgba(154, 159, 180, 0.7);
//  }
//  &::-webkit-scrollbar-track {
//    /*滚动条里面轨道*/
//    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
//    border-radius: 10px;
//    background: #ededed;
//  }
//}
